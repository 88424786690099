 
const config = {
    whoamiURL: 'https://auth.runi.de/sessions/whoami',
    authRegistrationGetFlowURL: 'https://auth.runi.de/self-service/registration/browser',
    authRegistrationFlowsURL: 'https://auth.runi.de/self-service/registration/flows',
    authRegistrationURL: 'https://auth.runi.de/self-service/registration',
    authLoginGetFlowURL: 'https://auth.runi.de/self-service/login/browser',
    authLoginFlowsURL: 'https://auth.runi.de/self-service/login/flows',
    authLoginURL: 'https://auth.runi.de/self-service/login',
    getLogoutURL: 'https://auth.runi.de/logout/browser'
}

export default config;