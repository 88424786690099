import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, TouchableOpacity, Text, Linking, Dimensions } from 'react-native';
import { URL } from 'react-native-url-polyfill';
import * as Font from 'expo-font';
import ScoreTable from './ScoreTable';
import { HoleData } from '../types/types';
import { getScoreName } from '../services/ScoreService';
import Svg, { Polyline } from 'react-native-svg';

import Themes from './EnterScoreThemes';

export type ScoreData = {
  players: Array<PlayerData>;
  rounddata: RoundData;
  tournamentdata: TournamentData;
}

export type TournamentData = {
  "tournamentid": number;
  "name": string;
  "activeroundindex": number;
  "mode": number;
  "status": number;
}

export type HolesData = {
  "1": HoleData;
  "2": HoleData;
  "3": HoleData;
  "4": HoleData;
  "5": HoleData;
  "6": HoleData;
  "7": HoleData;
  "8": HoleData;
  "9": HoleData;
  "10": HoleData;
  "11": HoleData;
  "12": HoleData;
  "13": HoleData;
  "14": HoleData;
  "15": HoleData;
  "16": HoleData;
  "17": HoleData;
  "18": HoleData;
}

export type RoundData = {
  date: string;
  hcpfactor: number;
  roundid: number;
  roundindex: number;
}

export type CourseData = {
  ID: number;
  course_id: number;
  name: string;
  tee_id: number;
  tee_name: string;
  holes: HolesData;
}

export type ScoreEntry = {
  s: number;
  tc: number;
}
export type PlayerData = {
    playerid:number;
    status: number;
    firstname: string;
    lastname: string;
    hcp: number;
    coursecombiid: number;
    startindex: number;
    genderid: number;
    teeid: number;
    scoretype: number;
    scores: Array<ScoreEntry>
}

export type ScoreTableDataEntry = {
  holeIndex: number;
  playerId: number;
  name: string;
  previousScore: number;
  currentScore: number;
}

type ScoreInputEntry = {
  holeIndex: number;
  playerId: number;
  currentScore: number;
}

type ScoreEditData = {
  
  customScore: number;
  customBackgroundColor: string;
  customTextColor: string;
  customBorderWidth: number;
} & ScoreInputEntry;

type State = {
  tournamentdata?: TournamentData;
  playersData : Array<any>;
  previousHole: number;
  currentHole: number;
  scoreTableData: Array<ScoreTableDataEntry> | null;
  enterActive: ScoreEditData | null;
  fontsLoaded: boolean;
  refreshScoreTable: boolean;
  currentHoleData: HoleData;
  scoreTableTheme: string;
  sendingScores: boolean;
  sendStateColor: 'green' | 'red' | 'black';
  receiveStateColor: 'green' | 'red' | 'black' | 'white';
}

export default class EnterScore extends Component<{gid: string}, State> {

  private scoredata : ScoreData | null = null;
  private coursedata : CourseData | null = null;
  private pendingScores : Array<ScoreInputEntry> = [];
  private tickInterval : NodeJS.Timer;
  private currentThemeName: string = 'dark';

  constructor(props) {
    super(props);

    this.state = {
      playersData: [],
      previousHole: 0,
      currentHole: 1,
      scoreTableData: null,
      enterActive: null,
      fontsLoaded: false,
      refreshScoreTable: false,
      currentHoleData: { p: 0, l: 0, h: 0},
      scoreTableTheme: 'dark',
      sendingScores: false,
      sendStateColor: 'green',
      receiveStateColor: 'green'
    }
  }
 
  async loadFonts() {
    await Font.loadAsync({
      'LCDMono': require('../assets/fonts/alarmclock.ttf'),
    });
    await Font.loadAsync({
      'roboto': require('../assets/fonts/roboto/Roboto-Regular.ttf'),
      'roboto_light': require('../assets/fonts/roboto/Roboto-Light.ttf'),
      'roboto_bold': require('../assets/fonts/roboto/Roboto-Bold.ttf'),
    });
    this.setState({ fontsLoaded: true });
  }

  async componentDidMount()
  {
    this.loadFonts();
    await this.fetchScoresData();
    await this.fetchCourseData();
    this.processScores();

    this.tickInterval = setInterval(this.tick, 5000);
  }

  componentWillUnmount(): void {
    if(this.tickInterval) {
      clearInterval(this.tickInterval)
    }
  }

  tick() {
    if(this.pendingScores?.length > 0 && !this.state.sendingScores) {
      this.trySendPendingScores();
    }
  }

  async fetchScoresData()
  {
    let newURL = await Linking.getInitialURL();

    if(newURL) {
      const url = new URL(newURL);
      const group_id = this.props.gid; //url.searchParams.get('gid');
    
      await fetch(`https://live-golf.de/fetchscoreupdate.php?gid=${group_id}`).then(
        (response) => response.json().then(
          (jsonresponse) => {
            this.scoredata = jsonresponse;
            this.setState({ tournamentdata: this.scoredata.tournamentdata});
          }),
          (reason) => {
            alert("error: " + JSON.stringify(reason));
          }
      )
    }    
  }

  async fetchCourseData() {
    if(this.scoredata?.players?.length && this.scoredata.players[0].coursecombiid) {
      const ccid = this.scoredata.players[0].coursecombiid;
      //console.log("ccid: ", ccid);
      await fetch(`https://live-golf.de/get_course_combi_data.php?ccid=${ccid}`).then(
        (response) => response.json().then(
          (jsonresponse) => {
            this.coursedata = jsonresponse;
            //console.log("CourseData: ", this.coursedata);
          }),
          (reason) => {
            alert("error: " + JSON.stringify(reason));
          }
      )
    }
  }

  getScoreColor(score: number, par: number) {
    const scorename = getScoreName(score, par);
    return Themes[this.currentThemeName].ScoreColors[scorename];
  }

  processScores() {
    const currentHole = this.getCurrentHole();
    //console.log("Cur Hole: ", currentHole);
    this.jumpToHole(currentHole);
  }

  jumpToHole(holeIndex: number) {
    if(holeIndex < 0) {
      holeIndex = 0;
    }

    if(holeIndex > this.getCurrentHole()) {
      holeIndex = this.getCurrentHole();
    }

    this.setState({ 
      currentHole: holeIndex, 
      previousHole: holeIndex-1,
      scoreTableData: this.getScoreTableData(holeIndex),
      currentHoleData: this.coursedata.holes[`${ holeIndex+1 }`]
    })
  }

  addPendingScore(playerId: number, holeIndex: number, score: number) {

    this.pendingScores.map((scoreEntry) => {
      if(scoreEntry.playerId == playerId && scoreEntry.holeIndex == holeIndex) {
        scoreEntry.currentScore = score;
        return;
      }
    })

    this.pendingScores.push({ playerId: playerId, holeIndex: holeIndex, currentScore: score});
  }

  getCurrentHole() : number {
    if(this.scoredata 
      && this.scoredata.players 
      && this.scoredata.players.length > 0 
      && this.scoredata.players[0].scores
      && this.scoredata.players[0].scores.length > 0
      ) {
        return Math.min(17, this.scoredata.players[0].scores.length);
      }
    return 0;
  }

  getScoreTableData(currentHole: number) : Array<ScoreTableDataEntry> {
    var scoreTableData : Array<ScoreTableDataEntry> = [];
    
    this.scoredata?.players.map((playerData) => {
      var curEntry : ScoreTableDataEntry = { holeIndex: currentHole, playerId: playerData.playerid, name: `${playerData.firstname} ${playerData.lastname}`, previousScore: this.getPlayerScore(playerData, currentHole-1), currentScore: this.getPlayerScore(playerData, currentHole)};
      scoreTableData.push(curEntry);
    });

    return scoreTableData;
  }

  getPlayerScore(playerData: PlayerData, holeIndex: number) : number {
    if(holeIndex >= 0 && holeIndex < 18 && playerData.scores && playerData.scores.length > holeIndex && playerData.scores[holeIndex]) {
      return playerData.scores[holeIndex].s;
    }
    return 0;
  }

  startEnterPreviousScore(holeIndex: number, playerId: number, score: number) {
    if(this.state.enterActive) {
      this.setState({ enterActive: null });
    }
    else {
      this.startEnterScore(score, holeIndex, playerId);
    }
  }

  startEnterCurrentScore(holeIndex: number, playerId: number, score: number) {
    if(this.state.enterActive) {
      this.setState({ enterActive: null });
    }
    else {
      this.startEnterScore(score, holeIndex, playerId);
    }
  }

  renderNameRows() {
    var rows : Array<JSX.Element> = [];

    if(this.state.scoreTableData?.length)
    {
      
      this.state.scoreTableData.map((data, index) => {

        var backgroundColor = '#000';
        var textColor = '#0FF';
        var previousBackgroundColor = '#000';
        var previousTextColor = '#0FF';
        var currentBackgroundColor = '#000';
        var currentTextColor = '#0FF';

        if(this.state.enterActive) {
          if(this.state.enterActive.playerId == data.playerId) {
            backgroundColor = '#0FF';
            textColor = '#000';

            if(this.state.enterActive.holeIndex == data.holeIndex-1) {
              previousBackgroundColor = '#0FF';
              previousTextColor = '#000';
            }
            else if(this.state.enterActive.holeIndex == data.holeIndex) {
              currentBackgroundColor = '#0FF';
              currentTextColor = '#000';
            }
          }
        }

        rows.push((
          <View key={`name_row_${index}`} style={{ flexDirection: 'row', width: '100%' }}>
            <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, backgroundColor: backgroundColor, borderColor: '#BBB', borderBottomWidth: 1, borderLeftWidth: 1 }}>
              <Text numberOfLines={1} style={[styles.scoreTableText, { color: textColor}]}>{data.name}</Text>
            </View>
            <TouchableOpacity onPress={() => { if(data.previousScore > 0) { this.startEnterPreviousScore(data.holeIndex-1, data.playerId, data.previousScore)}}}>
              <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, backgroundColor: previousBackgroundColor, borderColor: '#BBB', borderBottomWidth: 1, borderLeftWidth: 1 }}>
                <Text style={[styles.scoreTableText, { color: previousTextColor}]}>{data.previousScore > 0 ? data.previousScore : '-'}</Text>
              </View>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => { if(data.previousScore > 0 || data.holeIndex == 0) { this.startEnterCurrentScore(data.holeIndex, data.playerId, data.currentScore)}}}>
              <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, borderColor: '#BBB', backgroundColor: currentBackgroundColor, borderBottomWidth: 1, borderLeftWidth: 1, borderRightWidth: 1 }}>
                <Text style={[styles.scoreTableText, { color: currentTextColor}]}>{ data.currentScore > 0 ? data.currentScore : '-'}</Text>
              </View>
            </TouchableOpacity>
          </View>
        ))
        
      })
    }
    return rows;
  }

  renderScoreTable() {
    const prevHole = this.state.previousHole == -1 ? '-' : this.state.previousHole+1;
    return (
      <View style={{ minWidth: 280 }}>
      <View style={{ flexDirection: 'row', width: '100%'}}>
        <View style={{ justifyContent: 'center', alignItems: 'center', flex: 1, borderColor: '#BBB', borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 1 }}>
          <Text style={styles.headerText}>Hole</Text>
        </View>
        <TouchableOpacity onPress={ () => this.jumpToHole(this.state.currentHole-1)}>
          <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, borderColor: '#BBB', borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 1 }}>
            <Text style={styles.headerText}>{prevHole}</Text>
          </View>
        </TouchableOpacity>
        <TouchableOpacity onPress={ () => this.jumpToHole(this.state.currentHole+1)}>
          <View style={{ justifyContent: 'center', alignItems: 'center', width: 48, borderColor: '#BBB', borderBottomWidth: 1, borderTopWidth: 1, borderLeftWidth: 1, borderRightWidth: 1 }}>
            <Text style={styles.headerText}>{ this.state.currentHole+1 }</Text>
          </View>
        </TouchableOpacity>
      </View>
        { this.renderNameRows() }
      
      </View>
    )
  }

  onScorePressed(score: number | string) {

    if(typeof score == 'number') {
      this.addPendingScore(this.state.enterActive.playerId, this.state.enterActive.holeIndex, score);

      const curPlayerId = this.state.enterActive.playerId;
      const curHoleIndex = this.state.enterActive.holeIndex;
      const isCurrentHole = curHoleIndex == this.state.scoreTableData[0].holeIndex;

      this.setPlayerScore(curPlayerId, curHoleIndex, score);
      this.setState({scoreTableData: this.getScoreTableData(isCurrentHole ? curHoleIndex : curHoleIndex + 1)});

      this.gotoNextPlayer();
    }
    else if(typeof score == 'string') {
      if(score == 'wd') {
        console.log("WD: ", this.state.enterActive.playerId);
        this.gotoNextPlayer();
      }
    }
  }

  setPlayerScore(playerId: number, holeIndex: number, score: number) {
    //console.log("ScoreData: ", this.scoredata);
    for(var i=0; i<this.scoredata.players.length; ++i) {
      if(this.scoredata.players[i].playerid == playerId) {
        if(this.scoredata.players[i].scores && this.scoredata.players[i].scores[holeIndex]) {
          this.scoredata.players[i].scores[holeIndex].s = score;
        }
        else {
          if(!this.scoredata.players[i].scores) {
            this.scoredata.players[i].scores = [];
          }
          this.scoredata.players[i].scores[holeIndex] = { s: score, tc: 0};
        }
        
        break;
      }
    }
  }

  gotoNextPlayer() {
    const curHoleIndex = this.state.enterActive.holeIndex;
    const nextPlayer = this.getNextPlayer();
    this.setState({ enterActive : null}, () => {
      //console.log("Next Player: ", nextPlayer);
      if(nextPlayer) {
        
        this.startEnterScore(0, curHoleIndex, nextPlayer);
      }
      else {
        this.processScores();
        console.log("Pending: ", this.pendingScores);
        this.trySendPendingScores();
      }
    });
  }

  startEnterScore(currentScore: number, holeIndex: number, playerId: number) {
    //this.setState({ currentCustomScoreInput: 16 });
    const customScoreColor = this.getScoreColor(16, 4);
    //this.setState({ enterActive: { ...this.state.enterActive, customBackgroundColor: customScoreColor.backgroundColor, customTextColor: customScoreColor.textColor }})

    this.setState({ enterActive: { currentScore: currentScore, holeIndex: holeIndex, playerId: playerId, customScore: 16, customBackgroundColor: customScoreColor.background, customTextColor: customScoreColor.text, customBorderWidth: 2 }});
  }

  getNextPlayer() : number | null {
    const curPlayerId = this.state.enterActive.playerId;
    const curHoleIndex = this.state.enterActive.holeIndex;
    const isCurrentHole = curHoleIndex == this.state.scoreTableData[0].holeIndex;

    for(var i=0; i<this.state.scoreTableData.length; ++i) {
      const playerData = this.state.scoreTableData[i];
        
      if(playerData.playerId != curPlayerId) {
          
        if(isCurrentHole && playerData.currentScore == 0
          || !isCurrentHole && playerData.previousScore == 0)
        {
          //console.log("ret ", playerData.playerId);
          return playerData.playerId;
        }
      }
    }
    
    return null;
    
  }

  async trySendPendingScores() {
    if(this.state.sendingScores || this.pendingScores.length == 0) {
      return;
    }

    this.setState({ sendingScores: true, sendStateColor: 'black'})
    const sendingScores = JSON.parse(JSON.stringify(this.pendingScores));
    this.pendingScores = [];
    let newURL = await Linking.getInitialURL();

    if(newURL) {
      const url = new URL(newURL);
      const group_id = url.searchParams.get('gid');

      var payload = {
        groupid: group_id,
        players: {}
      }
    }

    sendingScores.map((scoreData) => {
      const playerIdString : string = scoreData.playerId.toString();
      const holeIndexString : string = scoreData.holeIndex.toString();

      if(Object.hasOwn(payload.players, playerIdString)) {
        payload.players[playerIdString][holeIndexString] = { s: scoreData.currentScore };
      }
      else {
        payload.players[playerIdString] = { [holeIndexString] : { s: scoreData.currentScore}};
      }
    })

    fetch('https://live-golf.de/submitscores.php', {
      method: 'post',
      headers: {'Content-Type':'application/json'},
      body: JSON.stringify(payload)
     }).then(
      (res) => {
        if(res.status == 200) {
          // refresh score table
          this.setState({ refreshScoreTable: !this.state.refreshScoreTable, sendingScores: false, sendStateColor: 'green' });
        }
        else {
          const tempPendingScores = JSON.parse(JSON.stringify(this.pendingScores));
          this.pendingScores = sendingScores;
          this.pendingScores.push(tempPendingScores);
          this.setState({ sendingScores: false, sendStateColor: 'red'});
        }

        
      },
      (err) => {
        console.log("Failed sending scores: ", err);
        const tempPendingScores = JSON.parse(JSON.stringify(this.pendingScores));
        this.pendingScores = sendingScores;
        this.pendingScores.push(tempPendingScores);
        this.setState({ sendingScores: false, sendStateColor: 'red'});
      }
     );

    //console.log("payload: ", payload);
  }

  renderScoreInput() {

    var scoreRows : Array<JSX.Element> = [];

    const holeIndex = this.state.enterActive.holeIndex + 1;
    const holePar = this.coursedata.holes[`${holeIndex}`].p;

    for(var i=2; i<16; ++i) {

      var borderColor = '#888';
      var borderWidth = 2;

      const holeColor = this.getScoreColor(i, holePar);

      if(this.state.enterActive.currentScore == i) {
        borderColor = '#FF0';
        borderWidth = 6;
      }
      scoreRows.push(
        
        <ScoreInput 
          backgroundColor={holeColor.background} 
          borderColor={borderColor} 
          borderWidth={borderWidth} 
          input={i} 
          label={i.toString()} 
          onPressed={this.onScorePressed.bind(this)} 
          textColor={holeColor.text}
          fontFamily='LCDMono'
          fontSize={70}
        />
        
      )
    }

    return (
      <View style={{ height: '100%'}}>
        <ScrollView>
          <View style={{ flexDirection: 'column', alignSelf: 'flex-end', width: this.state.enterActive ? 80 : 0, height: '100%', backgroundColor: '#000'}}>
            { scoreRows }
            <ScoreInput 
              backgroundColor="#000"
              borderColor={borderColor}
              borderWidth={2}
              input=""
              label="+"
              onPressed={() => {
                  const newScore = this.state.enterActive.customScore + 1;
                  const customColor = this.getScoreColor(newScore, holePar);
                  this.setState({ enterActive: { ...this.state.enterActive, customScore: newScore, customBackgroundColor: customColor.background, customTextColor: customColor.text }});
                }
              }
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />
            <ScoreInput 
              backgroundColor={ this.state.enterActive.customBackgroundColor}
              borderColor={borderColor}
              borderWidth={2}
              input={this.state.enterActive.customScore}
              label={this.state.enterActive.customScore.toString()}
              onPressed={this.onScorePressed.bind(this)}
              textColor={ this.state.enterActive.customTextColor}
              fontFamily='LCDMono'
              fontSize={60}
            />
            <ScoreInput 
              backgroundColor="#000"
              borderColor={borderColor}
              borderWidth={2}
              input=""
              label="-"
              onPressed={() => {
                  const newScore = Math.max(1, this.state.enterActive.customScore - 1);
                  const customColor = this.getScoreColor(newScore, holePar);
                  this.setState({ enterActive: { ...this.state.enterActive, customScore: newScore, customBackgroundColor: customColor.background, customTextColor: customColor.text}});
                }
              }
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />
            <ScoreInput 
              backgroundColor="#000" 
              borderColor={borderColor} 
              borderWidth={2} 
              input="wd" 
              label="WD" 
              onPressed={this.onScorePressed.bind(this)} 
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />
            <ScoreInput 
              backgroundColor="#000"
              borderColor={borderColor}
              borderWidth={2}
              input="dq"
              label="DQ"
              onPressed={this.onScorePressed.bind(this)}
              textColor="#FFF"
              fontFamily='roboto'
              fontSize={48}
            />  
          </View>
        </ScrollView>
      </View>
    )
  }

  onFetchStateChange(state: string) {
    if(state == 'fetching') {
      this.setState({receiveStateColor: 'black'});
    }
    else if(state == 'success') {
      this.setState({receiveStateColor: 'green'});
    }
    else if(state == 'error') {
      this.setState({receiveStateColor: 'red'});
    }
    else {
      this.setState({receiveStateColor: 'white'});
    }
  }

  onThemePressed() {
    if(this.state.scoreTableTheme == 'dark') 
    { 
      this.setState({ scoreTableTheme: 'light' }) ;
      this.currentThemeName = 'light';
      this.forceUpdate();
    }
    else {
      this.setState({ scoreTableTheme: 'dark' }) ;
      this.currentThemeName = 'dark';
      this.forceUpdate();
    }
  }
  render() {    

    const windowWidth = Dimensions.get('window').width;

    if(!this.state.fontsLoaded) {
      return null;
    }
    return (
      
      <View style={[ styles.container, {backgroundColor: Themes[this.currentThemeName].ContainerBackgroundColor}]}>
        <ScrollView>
        <View style={{ flex: 1, alignContent: 'center', alignItems: 'center'}}>
          <View>
            { this.state.tournamentdata && 
              <Text style={ styles.titleText }>{ this.state.tournamentdata.name }</Text>
            }
          </View>
          <View style={{ alignSelf: 'center', backgroundColor: '#000'}}>
            { this.renderScoreTable() }
          </View>
          <View>
            <Text style={styles.courseDataText}>
              { `Hole ${this.state.currentHole + 1} - Par ${this.state.currentHoleData.p} - ${this.state.currentHoleData.l}m` }
            </Text>
          </View>
          { this.state.tournamentdata &&
            <ScrollView horizontal={true} contentContainerStyle={{ maxWidth: windowWidth }}>
              <ScoreTable tid={this.state.tournamentdata.tournamentid} reload={this.state.refreshScoreTable} themeName={ this.state.scoreTableTheme } fetchStateCallback={this.onFetchStateChange.bind(this)}/>
            </ScrollView>
          }
        </View>
        <View style={{ alignSelf: 'center', marginTop: 16, flexDirection: 'row'}}>
          <Svg height="64" width="24" viewBox="0 0 30 60">
            <Polyline stroke={this.state.sendStateColor} fill={this.state.sendStateColor} points="19,60 19,25 29,28 16,2 2,28 12,25 12,60"></Polyline>
            </Svg>
          <Svg height="64" width="24" viewBox="0 0 30 60">
            <Polyline stroke={this.state.receiveStateColor} fill={this.state.receiveStateColor} points="19,2 19,36 29,33 16,60 2,33 12,36 12,2"></Polyline>
          </Svg>
        </View>
        <View style={{ alignSelf: 'center', marginTop: 16}}>
          <TouchableOpacity onPress={ this.onThemePressed.bind(this) }><Text style={{ color: '#FFF'}}>Test</Text></TouchableOpacity>
        </View>
        </ScrollView>
        { this.state.enterActive && 
          this.renderScoreInput()
        }
      </View>
      
    )
  }
}

const TopPadding = 30;

const styles = StyleSheet.create({
  container: { flex: 1, width: '100%', height: '100%', paddingTop: TopPadding, backgroundColor: '#333', flexDirection: 'row' },
  header: { height: 50, backgroundColor: '#537791' },
  text: { textAlign: 'center', fontWeight: '100', fontSize: 20 },
  titleText: { color: '#FFF', fontWeight: '400', fontSize: 32, paddingVertical: 16 },
  headerText: { color: '#FFF', fontWeight: '400', fontSize: 28, paddingHorizontal: 12, paddingVertical: 4 },
  scoreTableText: { fontFamily: 'roboto', color: '#0FF', fontWeight: '300', fontSize: 28, paddingHorizontal: 8, paddingVertical: 4 },
  dataWrapper: { marginTop: -1 },
  row: { height: 40, backgroundColor: '#E7E6E1' },
  col: { height: 40, backgroundColor: '#F00', flexDirection: 'column'},
  btn: { width: 58, height: 16, marginLeft: 1, backgroundColor: '#c8e1ff', borderRadius: 2 },
  btnText: { textAlign: 'center' },
  courseDataText: { fontFamily: 'roboto',color: '#FFF', fontWeight: '400', fontSize: 22, paddingVertical: 4 }
});

type ScoreInputProps = {
  input: string | number;
  label: string;
  onPressed: (input: string | number) => void;
  backgroundColor: string;
  textColor: string;
  borderWidth: number;
  borderColor: string;
  fontFamily: string;
  fontSize: number;
}

class ScoreInput extends React.Component<ScoreInputProps> {
  render() {

    return (
      <TouchableOpacity key={`scoreinput_${this.props.input}`} onPress={() => this.props.onPressed(this.props.input)}>
        <View style={{ marginTop: 2, height: 80, justifyContent: 'center', alignItems: 'center', backgroundColor: this.props.backgroundColor, borderRadius: 4, borderWidth: this.props.borderWidth, borderColor: this.props.borderColor}}>
          <Text style={{ fontFamily: this.props.fontFamily, fontSize: this.props.fontSize, color: this.props.textColor, textAlign: 'center' }}>{this.props.label}</Text>
        </View>
      </TouchableOpacity>
    )
  }
}
