import * as React from 'react';
import { Platform, StatusBar, StyleSheet, View } from 'react-native';

import { NavigationContainer } from '@react-navigation/native';
import { createStackNavigator } from '@react-navigation/stack';

import HomeScreen from './screens/HomeScreen';
import ScoreScreen from './screens/ScoreScreen';
import LoginScreen from './screens/LoginScreen';
import RegisterScreen from './screens/RegisterScreen';

export type RootStackParamList = {
  Home: undefined;
  Score: { gid: string };
  Register: { flow: string };
  Login: { flow: string };
};

const Stack = createStackNavigator();

const config = {
  screens: {
    Score: 'score/:gid',
    Home: 'home',
    Login: 'login',
    Register: 'register'
  },
};

const linking = {
  prefixes: ['http://localhost:19006', 'https://live-golf.de'],
  config,
};

function App(props) {
  //const [isLoadingComplete, setLoadingComplete] = React.useState(false);
  

  // Load any resources or data that we need prior to rendering the app

  return (
    <View style={styles.container}>
      <NavigationContainer linking={linking}>
        <Stack.Navigator screenOptions={{ headerShown: false }}>
          <Stack.Screen name="Home" component={HomeScreen} />
          <Stack.Screen name="Score" component={ScoreScreen} />
          <Stack.Screen name="Login" component={LoginScreen} />
          <Stack.Screen name="Register" component={RegisterScreen} />
        </Stack.Navigator>
      </NavigationContainer>
    </View>
  );
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#f0f',
  },
});

export default App;