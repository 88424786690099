import { StackScreenProps } from '@react-navigation/stack';
import * as React from 'react';
import { Component } from 'react';
import { StyleSheet, View, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import type { NativeStackScreenProps } from '@react-navigation/native-stack';
import EnterScore from '../components/EnterScore';
import { RootStackParamList } from '../App';

type Props = NativeStackScreenProps<RootStackParamList, 'Score'>;

const windowHeight = Dimensions.get('window').height;



export default class ScoreScreen extends Component<Props> {

  constructor(props) {
    super(props);

    console.log(this.props);
  }
  
  render() {
    const gid = this.props.route.params.gid;//"tfgrgn";
    //this.props.route.params.gid

    return (
      <View style={styles.container}>
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight}}>
            <EnterScore gid={gid}/>
        </ScrollView>
      </View>
    );
  }
}

/*ScoreScreen.navigationOptions = {
  header: null
};*/


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#111',
  }
});
