import React, { Component } from 'react';
import { StyleSheet, View, ScrollView, TouchableOpacity, Text, FlexAlignType } from 'react-native';
import ScoreScreen from '../screens/ScoreScreen';
import { getFormattedScoreTime, getScoreColor, getScoreName } from '../services/ScoreService';
import { CourseCombi, HoleData, PlayerScore, ScoreTableCourseData, TournamentPlayerData, TournamentScoreData } from '../types/types';

import Themes from './ScoreTableThemes';

enum SortType
{
  none,
  asc_desc,
  desc_asc,
  asc,
  desc
}
const playerDataFilter = [
  {id: "$index", title: "#", width: 40, sortable: SortType.none}, 
  {id: "$playername", title: "Player", width: null, sortable: SortType.none}, 
  {id: "totaltopar", title: "Total", width: 60, sortable: SortType.asc_desc}, 
  {id: "through", title: "Thru", width: 60, sortable: SortType.desc_asc}, 
  {id: "points_net", title: "N", width: 40, sortable: SortType.desc_asc},
  {id: "points_gross", title: "Br", width: 40, sortable: SortType.desc_asc},
  {id: "totalscore", title: "S", width: 40, sortable: SortType.asc_desc},
  {id: "playerid", title: "pid", width: 40, sortable: SortType.none}
]

const alignArr : Array<FlexAlignType> = [ 'center', 'flex-start', 'center', 'center', 'center'];

type Props = {
  tid: number;
  reload: boolean;
  themeName: string;
  fetchStateCallback?: (state: string) => void
}

type State = {
     tableHead : Array<any>,
     widthArr : Array<number>,
     playersData : Array<any>,
     sortID: string,
     playerDetailVisible: Array<boolean>
 }
export default class ScoreTable extends Component<Props, State> {

  private scoredata : TournamentScoreData | undefined;
  private sortID : string = "totaltopar";
  private sortDefault : boolean = true;

  constructor(props) {
    super(props);

    const elementButton = (entry) => 
    	(
        <TouchableOpacity onPress={() => this._alertIndex(entry.id)}>
          <View style={styles.btn}>
            <Text style={styles.btnText}>{entry.title}</Text>
          </View>
        </TouchableOpacity>
      );

    let tableHeadArray = [];
    let widthArray = [];
    playerDataFilter.map((entry, index) => {
      if(entry.sortable !== SortType.none) 
      {
        tableHeadArray.push(elementButton(entry));
      }
      else
      {
        tableHeadArray.push(entry.title)
      }
      
      widthArray.push(entry.width);
    })

    this.state = {
      tableHead: tableHeadArray,
      widthArr: widthArray,
      playersData: [],
      sortID: "totaltopar",
      playerDetailVisible: []
    }
  }

  _alertIndex(value) {
    //alert(`This is column ${value}`);

    if(this.sortID === value)
    {
      this.sortDefault = !this.sortDefault;
    }
    else
    {
      this.sortID = value;
      this.sortDefault = true;
    }

    this.processScores();
  }

  
  componentDidMount()
  {
    this.fetchScores();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if(prevProps.reload != this.props.reload) {
      this.fetchScores();
    }

    if(prevProps.themeName != this.props.themeName) {
      this.forceUpdate();
    }
  }

  processScores()
  {
    //console.log("ScoreData2: ", this.scoredata);
    //const coursedata = this.scoredata.coursecombis;
    const playerdata = this.scoredata.playerdata;
    //const scoreversion = this.scoredata.score_version;

    let playerArray = [];
    let playerIndex = 0;

    playerdata.map((c) => {
    
      playerIndex++;

      //const c = playerdata[playerid];

      let playerSummaryArray = [];
      playerDataFilter.map((entry, index) => {
        if(entry.id === "$index")
        {
          playerSummaryArray.push(playerIndex);
        }
        else if(entry.id === "$playername")
        {
          playerSummaryArray.push(c.firstname + " " + c.lastname);
        }
        else
        {
          playerSummaryArray.push(c[entry.id]);
        }
      })
      playerSummaryArray.push(parseInt(c.status));
      //console.log(c.status);
      playerArray.push(playerSummaryArray);
    });

    let indexIndex = -1;
    let sortIndex = 0;
    let sortType = SortType.none;

    for(let i=0; i<playerDataFilter.length; ++i)
    {
      if(playerDataFilter[i].id === "$index")
      {
        indexIndex = i;
      }

      if(playerDataFilter[i].id === this.sortID)
      {
        sortIndex = i;
        sortType = playerDataFilter[i].sortable;
      }
    }

    let sortAsc = sortType === SortType.asc 
    || (sortType === SortType.asc_desc && this.sortDefault)
    || (sortType === SortType.desc_asc && !this.sortDefault);

    //console.log(JSON.stringify(playerArray));
    playerArray.sort(function(a, b) { 

      let astatus = a[playerDataFilter.length];
      let bstatus = b[playerDataFilter.length];

      if(astatus !== bstatus)
      {
        if(astatus === 0)
        {
          //console.log(-1);
          return -1;
        }

        if(bstatus === 0)
        {
          //console.log(1);
          return 1;
        }

        if((astatus & 2) === 2)
        {
          //console.log(-1);
          return 1;
        }

        if((bstatus & 2) === 2)
        {
          //console.log(1);
          return -1;
        }

        if((astatus & 4) === 4 && (bstatus & 2) !== 2)
        {
          //console.log(-1);
          return 1;
        }
        else if((bstatus & 4) === 4 && (astatus & 2) !== 2)
        {
          //console.log(1);
          return -1;
        }

        if((astatus & 1) === 1)
        {
          //console.log(-1);
          return 1;
        }
        
    }
      

    if(sortAsc)
    {
      return a[sortIndex] > b[sortIndex] ? 1 : -1;
    }
    else
    {
      return a[sortIndex] < b[sortIndex] ? 1 : -1;
    }
  });

    
    if(indexIndex != -1)
    {
      for(let i=0; i<playerArray.length; ++i)
      {
        playerArray[i][indexIndex] = i+1;
      }
    }

    this.setState({ playersData : playerArray});

  }

  fetchScores()
  {
    if(this.props.fetchStateCallback) {
      this.props.fetchStateCallback('fetching');
    }
    fetch(`https://live-golf.de/fetchscores.php?tid=${this.props.tid}`).then(
    (response) => response.json().then(
      (jsonresponse) => {
        var playerdata = [];
        var coursecombis : Array<CourseCombi> = [];
        for(let playerid in jsonresponse.playerdata)
        {
          playerdata.push(jsonresponse.playerdata[playerid]);
        }

        for(let course_combi_id in jsonresponse.coursecombis)
        {
          coursecombis.push(jsonresponse.coursecombis[course_combi_id]);
        }

        this.scoredata = {
          score_version : jsonresponse.score_version,
          playerdata: playerdata,
          coursecombis: coursecombis
        }
        //this.scoredata = jsonresponse;
        //console.log("scores: ", this.scoredata);
        this.processScores();
        if(this.props.fetchStateCallback) {
          this.props.fetchStateCallback('success');
        }
        setTimeout(() => { this.fetchScores()}, 30000);
      }),
    (reason) => {
      alert("error: " + JSON.stringify(reason));
      if(this.props.fetchStateCallback) {
        this.props.fetchStateCallback('error');
      }
      setTimeout(() => { this.fetchScores()}, 30000);
    }
  )
  }

  renderPlayerRows() {
    var rows = [];

    this.state.playersData.map((playerData, index) => {
        rows.push(this.renderPlayerRow(index));
    })

    return rows;
  }

  renderPlayerRow(playerIndex: number) {
    var columns = [];
    const numColumns = 5;
    
    for(var i=0; i<numColumns; ++i) {
      columns.push(
        (
          <View style={
            { flex: this.state.widthArr[i] ? null : 1, 
              justifyContent: 'center', 
              alignItems: alignArr[i], 
              borderLeftWidth: 1, 
              borderColor: Themes[this.props.themeName].BorderColor, 
              borderTopWidth: 1, 
              width: this.state.widthArr[i], 
              backgroundColor: Themes[this.props.themeName].PlayerCellColor, 
              borderRightWidth: i == numColumns-1 ? 1 : 0,
              height: 32
            }}
          >
            <Text style={ styles.nameText }>{ this.state.playersData[playerIndex][i] }</Text>
          </View>
        )
      )
    }
    
    return(
      <View>
        <TouchableOpacity onPress={ () => { this.state.playerDetailVisible[playerIndex] = !this.state.playerDetailVisible[playerIndex]; this.forceUpdate() }}>
          <View style={
            { 
              flexDirection: 'row', 
              justifyContent: 'center', 
              alignContent: 'center', 
              alignItems: 'center', 
              borderBottomWidth: playerIndex == this.state.playersData.length-1 ? 1 : 0, 
              borderColor: Themes[this.props.themeName].BorderColor 
            }}
          >
            { columns }
          </View>
        </TouchableOpacity>
        { this.state.playerDetailVisible[playerIndex] && this.renderHoleScores(playerIndex) }
      </View>
    )
  }

  renderHeader() {
    const columnTextArr : Array<string> = [ 
      '#',
      'Name',
      'Total',
      'Thru',
      'S',
    ];

    const columnCount = 5;
    var columns = [];

    for(var i=0; i<columnCount; ++i) {
      columns.push( (
        <View style={
          { flex: this.state.widthArr[i] ? null : 1, 
            alignItems: alignArr[i], 
            borderLeftWidth: 1, 
            borderColor: Themes[this.props.themeName].BorderColor, 
            borderTopWidth: 1, 
            width: this.state.widthArr[i], 
            backgroundColor: Themes[this.props.themeName].HeaderBackgroundColor, 
            borderRightWidth: i == columnCount-1 ? 1 : 0
          }}
        >
          <Text style={ styles.headerText }>{columnTextArr[i]}</Text>
        </View>
      ))
    }
    return (
      <View style={{ flexDirection: 'row', justifyContent: 'center', alignContent: 'center', alignItems: 'center' }}>
        { columns }
      </View>
    )
  }

  getCourseData(playerId: number, roundIndex) : CourseCombi {
    var playerData : TournamentPlayerData | null;
    for(var i=0; i<this.scoredata.playerdata.length; ++i) {
      if(this.scoredata.playerdata[i].playerid == playerId) {
        playerData = this.scoredata.playerdata[i];
        break;
      }
    }
    if(playerData) {
      const courseCombiId = playerData.rounds[roundIndex].coursecombiid;

      for(var i=0; i<this.scoredata.coursecombis.length; ++i) {
        if(this.scoredata.coursecombis[i].ID == courseCombiId) {
          return this.scoredata.coursecombis[i];
        }
      }
    }

    return null;
  }

  getFront9Data(playerId: number, roundIndex: number) : ScoreTableCourseData {
    const courseData : CourseCombi = this.getCourseData(playerId, roundIndex);
    var parFront9 = 0;
    var lengthFront9 = 0;
    for(var i=1; i<10; ++i) {
      parFront9 += courseData.holes[i].p;
      lengthFront9 += courseData.holes[i].l;
    }

    return { par: parFront9, length: lengthFront9 };
  }

  getBack9Data(playerId: number, roundIndex: number) : ScoreTableCourseData {
    const courseData : CourseCombi = this.getCourseData(playerId, roundIndex);
    var parBack9 = 0;
    var lengthBack9 = 0;
    for(var i=10; i<19; ++i) {
      parBack9 += courseData.holes[i].p;
      lengthBack9 += courseData.holes[i].l;
    }
    
    return { par: parBack9, length: lengthBack9 };
  }

  getTimeFront9(playerId: number, roundIndex: number) {
    return 0;
  }

  getTimeBack9(playerId: number, roundIndex: number) {
    return 0;
  }

  getPlayerScores(playerId: number, roundIndex: number) : Array<PlayerScore> {
  
    for(var i=0; i<this.scoredata.playerdata.length; ++i) {
      if(this.scoredata.playerdata[i].playerid == playerId) {

        var scores = [];

        for(var j=0; j<18; ++j) {
          scores.push(this.scoredata.playerdata[i].rounds[roundIndex].scores[j+1])
        }
        return scores;
      }
    }
  }

  renderHoleScores(playerIndex: number) {
    const roundIndex = 0;
    const currentTheme = Themes[this.props.themeName];
    //console.log("PlayersData: ", this.state.playersData);
    const playerId = this.state.playersData[playerIndex][7];
    const playerScores = this.getPlayerScores(playerId, roundIndex);
    //const playerId = this.scoredata.playerdata[playerIndex].playerid;
    const cellStyle = [ styles.detailCell, { borderColor: currentTheme.ScoreDetailBorderColor, borderRightWidth: 0, borderBottomWidth: 0 }];
    const textStyle = [ styles.tableText, { color: currentTheme.ScoreDetailTextColor}];
    var rowNameColumn = (
      <View style={{backgroundColor: currentTheme.ScoreDetailLabelBackgroundColor}}>
        <View style={ cellStyle }>
          <Text style={ textStyle }>Hole</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>Par</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>Length</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>Round 1</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>Time</Text>
        </View>
      </View>
    );
    const courseData : CourseCombi = this.getCourseData(playerId, roundIndex);

    var scoreColumns1_9 = [];
    var front9TotalScore = 0;
    
    for(var i=0; i<9; ++i) {
      if(playerScores[i]) {
        front9TotalScore += playerScores[i].s;
      }
      const scorename = getScoreName(playerScores[i]?.s, courseData.holes[i+1].p);
      const scoreColor = Themes[this.props.themeName].ScoreColors[scorename];
      scoreColumns1_9.push(
        (
          <View style={{ alignItems: 'center'}}>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{i+1}</Text>
            </View>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{ courseData.holes[i+1].p }</Text>
            </View>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{ courseData.holes[i+1].l }</Text>
            </View>
            <View style={ [ cellStyle, { backgroundColor: scoreColor.background} ] }>
            <Text style={ [ textStyle, { color: scoreColor.text } ] }>{ playerScores[i]?.s ?? '--' }</Text>
            </View>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{ getFormattedScoreTime(playerScores[i]?.tc) }</Text>
            </View>
          </View>
        )
      )
    }


    var front9data = this.getFront9Data(playerId, roundIndex);
    var outColumn = (
      <View style={{ alignItems: 'center', backgroundColor: currentTheme.ScoreDetailLabelBackgroundColor}}>
        <View style={ cellStyle }>
          <Text style={ textStyle }>Out</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ front9data.par }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ front9data.length }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ front9TotalScore }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ this.getTimeFront9(playerId, roundIndex) }</Text>
        </View>
      </View>
    )

    var scoreColumns10_18 = [];
    var back9TotalScore = 0;
    
    for(var i=10; i<18; ++i) {
      if(playerScores[i]) {
        back9TotalScore += playerScores[i]?.s;
      }
      const scorename = getScoreName(playerScores[i]?.s, courseData.holes[i+1].p);
      const scoreColor = Themes[this.props.themeName].ScoreColors[scorename];
      scoreColumns10_18.push(
        (
          <View style={{ alignItems: 'center'}}>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{i+1}</Text>
            </View>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{ courseData.holes[i+1].p }</Text>
            </View>
            <View style={ cellStyle }>
              <Text style={ textStyle }>{ courseData.holes[i+1].l }</Text>
            </View>
            <View style={ [ cellStyle, { backgroundColor: scoreColor.background} ] }>
              <Text style={ [ textStyle, { color: scoreColor.text } ] }>{ playerScores[i]?.s ?? '--' }</Text>
            </View>
            <View style={ cellStyle }>
            <Text style={ textStyle }>{ getFormattedScoreTime(playerScores[i]?.tc) }</Text>
            </View>
          </View>
        )
      )
    }

    var back9data = this.getBack9Data(playerId, roundIndex);
    var inColumn = (
      <View style={{ alignItems: 'center', backgroundColor: currentTheme.ScoreDetailLabelBackgroundColor}}>
        <View style={ cellStyle }>
          <Text style={ textStyle }>In</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ back9data.par }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ back9data.length }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ back9TotalScore }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ this.getTimeBack9(playerId, roundIndex) }</Text>
        </View>
      </View>
    )

    var totalColumn = (
      <View style={{ alignItems: 'center', backgroundColor: currentTheme.ScoreDetailLabelBackgroundColor}}>
        <View style={ cellStyle }>
          <Text style={ textStyle }>Total</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ front9data.par + back9data.par }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ front9data.length + back9data.length }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ front9TotalScore + back9TotalScore }</Text>
        </View>
        <View style={ cellStyle }>
          <Text style={ textStyle }>{ this.getTimeFront9(playerId, roundIndex) + this.getTimeBack9(playerId, roundIndex) }</Text>
        </View>
      </View>
    )

    return (
    <View style={{ backgroundColor: Themes[this.props.themeName].ScoreDetailBackgroundColor, flexDirection: 'row'}}>
      <View style={{ height: '100%', borderTopWidth: 1, borderLeftWidth: 1, width: this.state.widthArr[0], backgroundColor: Themes[this.props.themeName].ScoreDetailLeftColumnColor}}>
      </View>
      <View style={{ flexDirection: 'row', borderRightWidth: 1, borderBottomWidth: 1, borderColor: Themes[this.props.themeName].ScoreDetailBorderColor}}>
        { rowNameColumn }
        { scoreColumns1_9 }
        { outColumn }
        { scoreColumns10_18 }
        { inColumn }
        { totalColumn }
      </View>
    </View>
    )
  }

  render() {
    return (
      <View style={[ styles.container, { backgroundColor: Themes[this.props.themeName].ContainerBackgroundColor}]}>
        <ScrollView horizontal={true}>
          <View>
            <>
              { this.renderHeader() }
              { this.state.playersData.length > 0 && this.renderPlayerRows() }
            </>
          </View>
        </ScrollView>
      </View>
    )
  }
}
 

const styles = StyleSheet.create({
  container: { flex: 1, padding: 4, marginTop: 24 },
  //header: { height: 40, backgroundColor: Themes[this.props.themeName].HeaderBackgroundColor },
  headerText: { paddingHorizontal: 8, fontSize: 16, fontFamily: 'roboto' },
  tableText: { padding: 2, paddingHorizontal: 8, fontSize: 16, fontFamily: 'roboto_light', fontWeight: '100' },
  nameText: { padding: 2, paddingHorizontal: 8, fontSize: 18, fontFamily: 'roboto_bold' },
  text: { textAlign: 'center', fontWeight: '100', fontSize: 20 },
  dataWrapper: { marginTop: -1 },
  col: { height: 28, backgroundColor: '#F00', flexDirection: 'column'},
  btn: { padding: 0, margin: 0, marginLeft: 1, backgroundColor: '#c8e1ff' },
  btnText: { textAlign: 'center', fontSize: 16 },
  detailCell: { width: '100%', borderWidth: 1, alignItems: 'center'}
});

