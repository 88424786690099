import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { StyleSheet, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { ScrollView, TextInput } from 'react-native-gesture-handler';
import { RootStackParamList } from '../App';
import * as AuthService from '../services/AuthService';
type Props = NativeStackScreenProps<RootStackParamList, 'Register'>;

const windowHeight = Dimensions.get('window').height;

type State = {
  email: string;
  password: string;
  showPassword: boolean;
}

export default class RegisterScreen extends React.Component<Props, State> {

  constructor(props) {
    super(props);
    this.state={ email: "", password: "", showPassword: false}
  }

  
  onRegisterPressed() {
    const flowId = this.props.route.params.flow;

    AuthService.registerUser({ flowId: flowId, email: this.state.email, password: this.state.password});
  }

  render() {

    return (
      <View style={styles.container}>
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight}}>
            <View style={{ alignItems: 'center'}}>
              <Text>Registration screen</Text>
              <View style={{ flexDirection: 'row' }}>
                <Text>Email:</Text>
                <TextInput onChangeText={(text) => this.setState({ email: text})} value={this.state.email} style={{ width: 400 }}/>
              </View>
              <View style={{ flexDirection: 'row' }}>
                <Text>Passwort:</Text>
                <TextInput secureTextEntry={!this.state.showPassword} onChangeText={(text) => this.setState({ password: text})} value={this.state.password} style={{ width: 400 }}/>
              </View>
              <TouchableOpacity onPress={this.onRegisterPressed.bind(this)}>
                <View style={{ width: 200, height: 24, backgroundColor: '#F00'}}>
                  <Text>Login</Text>
                </View>
              </TouchableOpacity>
            </View>  
        </ScrollView>
      </View>
    );
  }
}


const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#FFF',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(255,255,255,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
