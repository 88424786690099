import config from "../config/config";
import { AuthLoginData, AuthRegistrationData, FlowsData, WhoAmIResponse } from "../types/types";

export async function whoami() : Promise<WhoAmIResponse> {
  return new Promise((resolve, reject) => {
    fetch(config.whoamiURL, { credentials: 'include'}).then((res) => {
      res.json().then((sessionData) => {
        resolve(sessionData);
      },
      (err) => {
        reject(err);
      })
    })
  })
  
}

export async function registerUser(registrationData: AuthRegistrationData) {

  return new Promise<void>((resolve, reject) => {
    fetch(`${config.authRegistrationFlowsURL}?id=${registrationData.flowId}`,
    {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
      }
    }).then((flowData) => {
      flowData.json().then((jsonObject) => {
        console.log(jsonObject);

        const csrfToken = getCSRFTokenFromFlowJson(registrationData.flowId, jsonObject);

        if (csrfToken) {
          postRegistrationData(csrfToken, registrationData).then(() => {
            resolve();
          },
          (err) => {
            reject(err);
          });
        }
        else {
          reject("CSRF Token not found");
          return;
        }
      })
    })
  })
  
}

function getCSRFTokenFromFlowJson(flowId: string, flowsData: FlowsData) : string | null {

  if (flowsData?.ui?.nodes) {
    for (var i = 0; i < flowsData.ui.nodes.length; ++i) {
      const nodeData = flowsData.ui.nodes[i];

      if (nodeData.attributes?.name == 'csrf_token') {
        return nodeData.attributes.value;
      }
    }
  }

  return null;
}

async function postRegistrationData(csrfToken: string, registrationData: AuthRegistrationData) {
  
  return new Promise<void>((resolve, reject) => {
    fetch(`${config.authRegistrationURL}?flow=${registrationData.flowId}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        csrf_token: csrfToken,
        method: "password",
        password: registrationData.password,
        traits: {
          "email": registrationData.email
        }
      })
    }).then(
      (data) => {
        data.text().then((resval) => {
          console.log(resval);
          resolve();
        },
        (err) => {
          reject(err);
        })
      }
    )
  })
}

export async function loginUser(loginData: AuthLoginData) {

  return new Promise<void>((resolve, reject) => {
    fetch(`${config.authLoginFlowsURL}?id=${loginData.flowId}`,
    {
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
      }
    }).then((flowData) => {
      flowData.json().then((jsonObject) => {
        console.log(jsonObject);

        const csrfToken = getCSRFTokenFromLoginFlowJson(loginData.flowId, jsonObject);

        if (csrfToken) {
          postLoginData(csrfToken, loginData).then(() => {
            resolve();
          },
          (err) => {
            reject(err);
          });
        }
        else {
          reject("CSRF Token not found");
          return;
        }
      })
    })
  })
  
}

function getCSRFTokenFromLoginFlowJson(flowId: string, flowsData: FlowsData) : string | null {

  if (flowsData?.ui?.nodes) {
    for (var i = 0; i < flowsData.ui.nodes.length; ++i) {
      const nodeData = flowsData.ui.nodes[i];

      if (nodeData.attributes?.name == 'csrf_token') {
        return nodeData.attributes.value;
      }
    }
  }

  return null;
}

async function postLoginData(csrfToken: string, loginData: AuthLoginData) {
  
  return new Promise<void>((resolve, reject) => {
    fetch(`${config.authLoginURL}?flow=${loginData.flowId}`,
    {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        csrf_token: csrfToken,
        method: "password",
        password: loginData.password,
        identifier: loginData.email
      })
    }).then(
      (data) => {
        data.text().then((resval) => {
          console.log(resval);
          resolve();
        },
        (err) => {
          reject(err);
        })
      }
    )
  })
}