import { NativeStackScreenProps } from '@react-navigation/native-stack';
import * as React from 'react';
import { Image, Platform, StyleSheet, Text, TouchableOpacity, View, Dimensions } from 'react-native';
import { ScrollView } from 'react-native-gesture-handler';
import { RootStackParamList } from '../App';
import * as AuthService from '../services/AuthService';

type Props = NativeStackScreenProps<RootStackParamList, 'Home'>;
const windowHeight = Dimensions.get('window').height;

type State = {
  useremail: string;
}

export default class HomeScreen extends React.Component<Props, State> {

  private focusListener;

  constructor(props) {
    super(props);

    this.state= { useremail: "Not logged in"};
  }

  componentDidMount(): void {
    const { navigation } = this.props;
    this.focusListener = navigation.addListener("didFocus", () => {
      this.getUserData();
    });  

    this.getUserData();
  }

  getUserData() {
    AuthService.whoami().then((sessionData) => {
      this.setState({ useremail: sessionData.identity.traits.email});
    },
    (err) => {
      this.setState({ useremail: "Not logged in"});
    })
  }

  componentWillUnmount(): void {
    if(this.focusListener) {
      this.focusListener.remove();
    }
  }

  render() {
    return (
      <View style={styles.container}>
        <ScrollView style={styles.container} contentContainerStyle={{ height: windowHeight}}>
            <Text style={{color: '#FFF'}}>Home screen. User email: {this.state.useremail}</Text>
        </ScrollView>
      </View>
    );
  }
}

const styles = StyleSheet.create({
  container: {
    height: '100%',
    backgroundColor: '#111',
  },
  developmentModeText: {
    marginBottom: 20,
    color: 'rgba(0,0,0,0.4)',
    fontSize: 14,
    lineHeight: 19,
    textAlign: 'center',
  }
});
