import { ColorPair } from "../types/types";

export type EnterScoreTheme = {
    ContainerBackgroundColor: string;
    HeaderBackgroundColor: string;
    BorderColor: string;
    PlayerCellColor: string;
    ScoreDetailBackgroundColor: string;
    ScoreDetailLeftColumnColor: string;
    ScoreDetailTextColor: string;
    ScoreDetailBorderColor: string;
    ScoreDetailLabelBackgroundColor: string;
    ScoreColors: {
        default: ColorPair;
        Condor: ColorPair;
        DoubleEagle: ColorPair;
        Eagle : ColorPair;
        Birdie : ColorPair;
        Par: ColorPair;
        Bogey: ColorPair;
        DoubleBogey: ColorPair;
        TripleBogey: ColorPair;
        Other: ColorPair;
    }
}

const darkTheme : EnterScoreTheme = {
    ContainerBackgroundColor: '#333',
    HeaderBackgroundColor: '#666',
    BorderColor: '#000',
    PlayerCellColor: '#DEF',
    ScoreDetailBackgroundColor: '#333',
    ScoreDetailLeftColumnColor: '#AAA',
    ScoreDetailTextColor: '#DDD',
    ScoreDetailBorderColor: '#000',
    ScoreDetailLabelBackgroundColor: '#444',
    ScoreColors: {
        default: { background: '#222', text: '#FFF'},
        Condor: { background: '#000', text: '#FFF'},
        DoubleEagle: { background: '#0FF', text: '#F0F' },
        Eagle: { background: '#5bc236', text: '#000000' },
        Birdie: { background: '#32b1e4', text: '#000000' },
        Par: { background: '#dddddd', text: '#000000' },
        Bogey: { background: '#f7b942', text: '#000000' },
        DoubleBogey: { background: '#ee5a00', text: '#000000' },
        TripleBogey: { background: '#85357c', text: '#CCC' },
        Other: { background: '#9f5924', text: '#FFF' }
    }
}

const Themes : { [ key: string ] : EnterScoreTheme } = {
    default: darkTheme,
    dark: darkTheme,
    light: {
        ContainerBackgroundColor: '#EEE',
        HeaderBackgroundColor: '#666',
        BorderColor: '#CCC',
        PlayerCellColor: '#DDD',
        ScoreDetailBackgroundColor: '#EEE',
        ScoreDetailLeftColumnColor: '#111',
        ScoreDetailTextColor: '#000',
        ScoreDetailBorderColor: '#111',
        ScoreDetailLabelBackgroundColor: '#888',
        ScoreColors: {
            default: { background: '#EEE', text: '#000'},
            Condor: { background: '#FFF', text: '#000'},
            DoubleEagle: { background: '#16a1a4', text: '#000000' },
            Eagle: { background: '#5bc236', text: '#000000' },
            Birdie: { background: '#32b1e4', text: '#000000' },
            Par: { background: '#dddddd', text: '#000000' },
            Bogey: { background: '#f7b942', text: '#000000' },
            DoubleBogey: { background: '#ee5a00', text: '#000000' },
            TripleBogey: { background: '#85357c', text: '#000000' },
            Other: { background: '#9f5924', text: '#FFF' }
        }
    }
}

export default Themes;
